.project-section {
    margin-top: var(--margin-section);
    width: 100%;
}

.project-preview {
    margin-top: 7rem;
    overflow: hidden;
    border-radius: 0.5em;
    transition: transform 0.5s ease;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    align-items: flex-end;
}
.project-preview.expenses{
    background-image: url(../Images/ExpensesAppPreview.png);
}
.project-preview.yurban{
    background-image: url(../Images/YurbanPreview.png);
}

.project-content {
    --padding: 3rem;
    padding: 0 var(--padding) ;
    background: linear-gradient(
        hsl(0 0% 0%/ 0),
        hsl(212, 33%, 16%, 0.7) 5%,
        hsl(230, 25%, 5%, 1)
    );
    width: 100%;
}

.card-title {
    position: relative;
    width: max-content;
    margin-bottom: 2rem;
}
.card-title::after {
    content: '';
    position: absolute;
    height: 2px;
    left: calc(var(--padding) * -1);
    bottom: 0;
    width: calc(100% + var(--padding));
    background: var(--mint);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.tags h6 {
    border: 1px solid hsl(0, 0%, 100%, 0.7);
    border-radius: 3rem;
    padding: 0.5rem;
}
.tags, .project-content p{
    margin-bottom: 2rem;
}
.project-btns {
    display: flex;
    flex-direction: row;
    gap: 3%;
    padding-bottom: 1rem;
}
.button {
    width: 100%;
    padding: 0.5rem;
    /* border: 1px solid var(--mint); */
    border-radius: 0.2rem;
    text-align: center;
    background: #444857;
}
.button h4{
    color: var(--white);
    text-transform: uppercase;
}

.others h4:hover {
    text-decoration: underline ;

}

@media (hover) and (min-width: 768px){
    .project-content {
        transform: translateY(80%);
        transition: transform 1s ease;
    }

    .project-preview:hover,
    .project-preview:focus-within {
        transform: scale(1.05);
    }
    .project-preview:hover .project-content,
    .project-preview:focus-within .project-content {
        transform: translateY(0%);
        transition-delay: 0.5s;
    }
    .project-content:focus-within .project-content {
        transition-duration: 0ms;
    }
    .project-content > *:not(.card-title){
        opacity: 0;
        transition: opacity 0.5s ease;
    }
    .project-preview:hover .project-content > *,
    .project-preview:focus-within .project-content > *{
        opacity: 1;
        transition-delay: 1000ms;
    } 
    .project-preview:hover .card-title::after,
    .project-preview:focus-within .card-title::after {
        transform: scaleX(1);
    }
    .button:hover {
        background-color: rgba(14, 230, 183, 0.5);
    }
    .button:hover h4{
        color: var(--white);
    }
}


/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
 
@media only screen and (max-width: 1024px) {
    .project-preview {
        margin-top: var(--margin-section-tablet);
    }
}
@media only screen and (max-width: 768px) {
    .project-section {
        margin-top: var(--margin-section-tablet);
    }
    .projects {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .project-preview {
        background-position: top;
        background-size: 130%;
        width: 48%;
        height: 280px;
        display: flex;
        align-items: flex-end;
    }
    .project-content {
        padding: 0 1rem;
        max-width: 100%;
        background: linear-gradient(
        hsl(0 0% 0%/ 0),
        hsl(212, 33%, 16%, 0.5) 2%,
        hsl(212, 33%, 16%, 0.7) 5%,
        hsl(230, 25%, 5%, 1)s
    );
    }
    .project-content h3 {
        font-size: 1.3rem;
        max-width: 100%;
        margin-bottom: 0.8rem;
    } 
    .project-content h6 {
        font-size: 0.8rem;
    }
    .project-content p {
        display: none;
    }
    .tags {
        margin-bottom: 0.8rem;
    }
    .button h4 {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 550px) {
    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .project-preview {
        background-position: top;
        background-size: contain;
        width: 100%;
        height: 280px;
        display: flex;
        align-items: flex-end;
    }
    .project-content{
        width: 100%;
    }
} 