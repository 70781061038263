.navbar-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    width: 100%;
}

.navbar-links,
.navbar-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.navbar-links {
    width: 50%;
}

.links:hover {
    border-bottom: 0.063rem solid #fff;
}

.links:active,
.links > h5:active {
    border-bottom: 0.063rem solid rgba(255, 255, 255, 0.348);
    color: rgba(255, 255, 255, 0.348);
}

.navbar-logos {
    gap: 1.875rem;
}

.navbar-logos > a > img {
    border-radius: 0.3rem;
}

.navbar a:-webkit-any-link {
    text-decoration: none;
}

.logo-linkedin:hover,
.logo-github:hover {
    filter: drop-shadow(0.125rem 0.125rem 0.125rem #0077b5) invert(10%);
}

.navbar-mobile {
    display: none;
}

/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */

@media only screen and (max-width: 768px) {
    .navbar-links {
        width: 70%;
    }
}

@media only screen and (max-width: 550px) {

    .navbar-links {
        display: none;
    }
    .navbar-mobile {
        display: flex;
    }
    .links-show {
        display: flex;
        flex-direction: column;
    }
    .links-hide {
        display: none;
    }

    .expand-button {
        background: none;
        border: none;
    }

    .expand-button:active {
        background-color: rgba(255, 255, 255, 0.188);
    }

    .links {
        margin-bottom: 1rem;
    }
}