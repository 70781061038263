/* ================================================================================ */
/* ================================= INTRO SECTION ================================ */ 
/* ================================================================================ */

.intro {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    width: 100%;
  }
  
  .intro-title {
    position: absolute;
    z-index: 3;
    width: 50%;
  }
  
  .title-mint {
    color: var(--mint);
  }
  
  .title-inverse {
    display: inline-block;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg); 
  }
  
  .title-mint-border {
    font-weight: 700;
    font-size: 4.5rem;
    color:rgba(0,0,0,0);
    -webkit-text-stroke: 0.125rem var(--mint);
  }
  
  .intro-img {
    position: relative;
    width: 50%;
    left: 45%;
  }
  
  .intro-img > img {
    width: 100%;
  }

/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */

@media only screen and (max-width: 1042px) {
  .intro{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .intro-title {
    position: static;
    width: 75%;
  }

  .intro-img {
    position: static;
    width: 100%;
    margin-top: 2rem;
    align-items: center;
  }
}


@media only screen and (max-width: 550px) {
  .intro-title h1 {
    font-weight: 700;
    font-size: 3rem;
  }
}