.footer-section{
    margin-top: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5.625rem;
}

.logos-section > a > img {
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 0.625rem;
    margin-left: 1.563rem;
    margin-right: 1.563rem;
}

.end-section {
    width: 90%;
    border-top: 0.063rem solid #FFFFFF;
    text-align: center;
    font-size: 1rem;
}

.end-section > p {
    font-size: 1rem;
    margin-top: 0.625rem;
}

.footer-section a:-webkit-any-link {
    text-decoration: none;
}

h4.link:active {
    color: #79dec8;
}

h4.link:hover {
    border-bottom: 0.063rem solid #79dec8;
}

/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */

@media only screen and (max-width: 550px) {
    .footer-section {
        margin-top: 5rem;
    }
}