/* ================================================================================ */
/* ================================= ABOUT SECTION ================================ */ 
/* ================================================================================ */

.about {
    margin-top: var(--margin-section)
  }

  
  .about-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    margin-left: 1.25rem;
  }
  
  .about-text > h3,
  .about-text > p{
    margin: 1.875rem 0rem;
  }
  .about-text > h3 {
    line-height: 2.4rem;
  }
  .about-text > p {
    line-height: 1.75rem;
  }

  .about-text .bold {
    font-weight: 600;
  }
  
  .mint {
    color: #0EE6B7;
  } 

  .about a:-webkit-any-link {
    text-decoration: none;
    text-align: center;
  }

  .download-cv:hover {
    text-decoration: underline;
  }

  .download-cv:active {
    color: #79dec8;
  }

  .full-cube {
    position: relative;
    width: 12.5rem;
    height: 12.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  
    animation-name: spinning;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 6.25rem 6.25rem 0;
  }
  
  .cube > img {
    height: 5.625rem;
    width: 5.625rem;
  }
  
  .cube {
    align-items: center;
    background: hsla(0,0%,100%,.4);
  
    box-shadow: 0 0 1.25rem 0 #bbe3da;
    display: flex;
    font-size: 6.25rem;
    height: 12.5rem;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 12.5rem;
  }
  
  .face-1 {
    transform: translateZ(6.25rem);
  }
  .face-2 {
    transform: rotateY(90deg) translateZ(6.25rem);
  }
  .face-3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(6.25rem);
  }
  .face-4 {
    transform: rotateY(180deg) rotate(90deg) translateZ(6.25rem);
  }
  .face-5 {
    transform: rotateY(-90deg) rotate(90deg) translateZ(6.25rem);
  }
  .face-6 {
    -webkit-transform: rotateX(-90deg) translateZ(6.25rem);
    transform: rotateX(-90deg) translateZ(6.25rem);
  }
  
  @keyframes spinning {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }


/* ==================================================================================================================================== */
/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */

@media only screen and (max-width: 768px) {

  .about {
    margin-top: var(--margin-section-tablet);
  }

  .about-section {
    display: flex;
    flex-direction: column;
  }

  .cube-section {
    margin-top: 4.3rem;
  }
}