
.portfolio-container {
    background-image: url(../Images/SketchNoBackgr.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;

} 
.portfolio {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.portfolio h3{
    width: 53%;
    margin-bottom: 8rem;
}

.portfolio a {
    text-decoration: none;
    color: var(--mint);
}

.btn-section {
    display: flex;
    justify-content: flex-start;
}

.btn-back {
    background: none;
    padding: 0.4rem;
    color: var(--white);
    border-radius: 50%;
}

.btn-back:hover,
.btn-back:active {
    filter: drop-shadow(0.125rem 0.125rem 0.125rem #0077b5) invert(10%);
}

/* ==================================================================================================================================== */
/* ========================================================= QUERIES SECTION ========================================================== */
/* ==================================================================================================================================== */
/* ==================================================================================================================================== */

